<template>
  <div class="container account-settings-container">
    <div class="card p-1 text-first  border-0">
      <div class="card-body mx-auto">
        <h2 class="fw-bold">{{ $t("account.default.pageTitle") }}</h2>
        <div class="row">
          <h4 class="mb-4 mt-4">{{ $t("account.default.basicInformation") }}</h4>
          <div class="col-5">
            <label>{{ $t("account.default.labelLastName") }}</label>
            <input v-model="lastName" class="form-control" disabled name="inputSurname" type="text">
          </div>
          <div class="col-5">
            <label>{{ $t("account.default.labelFirstName") }}</label>
            <input v-model="firstName" class="form-control" disabled name="inputName" type="text">
          </div>
          <div class="col-2 p-0">
            <router-link :to="{ name: 'EditName' }" class="required-text change">{{
                $t("account.default.change")
              }}
            </router-link>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-10">
            <label>{{ $t("account.default.labelBirthday") }}</label>
            <input v-model="birthday" class="form-control" disabled name="inputBirthday" type="text">
            <!--<input type="date" name="inputBirthday" class="form-control" placeholder="1994/10/02">-->
          </div>
          <div class="col-2 p-0">
            <router-link :to="{ name: 'EditBirthday' }" class="required-text">{{
                $t("account.default.change")
              }}
            </router-link>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-10">
            <label>{{ $t("account.default.labelPassword") }}</label>
            <input class="form-control" disabled name="inputPassword" placeholder="●●●●●●●●●●●" type="password">
          </div>
          <div class="col-2 p-0">
            <router-link :to="{ name: 'EditPasswordRequired' }" class="required-text">{{
                $t("account.default.change")
              }}
            </router-link>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-10">
            <label>{{ $t("account.default.labelSex") }}</label>
            <input v-model="gender" class="form-control" disabled name="inputGender" type="text">
          </div>
          <div class="col-2 p-0">
            <router-link :to="{ name: 'EditSex' }" class="required-text">{{
                $t("account.default.change")
              }}
            </router-link>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-10">
            <label>{{ $t("account.default.nationality") }}</label>
            <input v-model="nationality" class="form-control" disabled name="inputCountryOfCitizenship" type="text">
          </div>
          <div class="col-2 p-0">
            <router-link :to="{ name: 'EditCountry' }" class="required-text">{{
                $t("account.default.change")
              }}
            </router-link>
          </div>
        </div>
        <div class="row">
          <h4 class="mb-4 mt-5">{{ $t("account.default.contactInformation") }}</h4>
          <div class="col">
            <label>{{ $t("account.default.labelEmail") }}</label>
            <input v-model="email" class="form-control" disabled name="inputMailAddress" type="email">
          </div>
          <div class="col-2 p-0">
            <router-link :to="{ name: 'EditMailAddress' }" class="required-text">{{
                $t("account.default.change")
              }}
            </router-link>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <label>{{ $t("account.default.address") }}</label>
            <input v-model="address" class="form-control" disabled name="inputStreetAddress" type="text">
          </div>
          <div class="col-2 p-0">
            <router-link :to="{ name: 'EditAddress' }" class="required-text">{{
                $t("account.default.change")
              }}
            </router-link>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <label>{{ $t("account.default.phoneNo") }}</label>
            <input v-model="phoneNo" class="form-control" disabled name="inputPhoneNumber" type="text">
          </div>
          <div class="col-2 p-0">
            <router-link :to="{ name: 'EditPhone' }" class="required-text">{{
                $t("account.default.change")
              }}
            </router-link>
          </div>
        </div>
<!--
        <div class="row">
          <h4 class="mb-4 mt-5">{{ $t("account.default.other") }}</h4>
          <label>{{ $t("account.default.hasCoronaVaccination") }}</label>
          <div class="col-5 vaccine-status">
            <input v-model="coronaVaccinationCount" class="form-control" disabled name="inputBasicInformationSaito"
                   type="text">
          </div>
          <div class="col-5 vaccine-date">
            <input v-model="latestCoronaVaccinationDate" class="form-control" disabled
                   name="inputBasicInformationMinatsu" type="text">
          </div>
          <div class="col-2 p-0" style="margin-top: -1.4rem;">
            <router-link :to="{ name: 'VaccineChange', params : { vaccinationData : vaccinationData } }"
                         class="required-text">{{ $t("account.default.change") }}
            </router-link>
          </div>
        </div>
        
        <div class="row mt-2">
          <div class="col-5 pcr-status">
            <label>{{ $t("account.default.latestPcrTestResult") }}</label>
            <input v-model="latestPcrTestResult" class="form-control" disabled name="inputPCRTestSaito" type="text">
          </div>
          <div class="col-5 pcr-date">
            <label>&nbsp;</label>
            <input v-model="latestPcrTestDate" class="form-control" disabled name="inputPCRTestMinatsu" type="text">
          </div>
          <div class="col-2 p-0">
            <router-link :to="{ name: 'PcrTestChange', params: { pcrTestData: pcrTestData } }" class="required-text">
              {{ $t("account.default.change") }}
            </router-link>
          </div>
        </div>
        -->
        <div class="row mt-4 medical-history">
          <label>{{ $t("medicalHistory.default.subtitle") }}</label>
          <div class="col-10">
            <div class="form-check ml-2 mx-auto m-t-10">
              <input
                  id="anemia"
                  v-model="anemia"
                  class="form-check-input"
                  disabled
                  type="checkbox"
              />
              <label class="form-check-label" for="anemia">
                {{ $t("medicalHistory.default.anemia") }}
              </label>
            </div>
            <div class="form-check ml-2 mx-auto m-t-10">
              <input
                  id="asthma"
                  v-model="asthma"
                  class="form-check-input"
                  disabled
                  type="checkbox"
              />
              <label class="form-check-label" for="asthma">
                {{ $t("medicalHistory.default.asthma") }}
              </label>
            </div>
            <div class="form-check ml-2 mx-auto m-t-10">
              <input
                  id="heartDisease"
                  v-model="heartDisease"
                  class="form-check-input"
                  disabled
                  type="checkbox"
              />
              <label class="form-check-label" for="heartDisease">
                {{ $t("medicalHistory.default.heartDisease") }}
              </label>
            </div>
            <div class="form-check ml-2 mx-auto m-t-10">
              <input
                  id="kidneyDisease"
                  v-model="kidneyDisease"
                  class="form-check-input"
                  disabled
                  type="checkbox"
              />
              <label class="form-check-label" for="kidneyDisease">
                {{ $t("medicalHistory.default.kidneyDisease") }}
              </label>
            </div>
            <div class="form-check ml-2 mx-auto m-t-10">
              <input
                  id="epilepsy"
                  v-model="epilepsy"
                  class="form-check-input"
                  disabled
                  type="checkbox"
              />
              <label class="form-check-label" for="epilepsy">
                {{ $t("medicalHistory.default.epilepsy") }}
              </label>
            </div>
            <div class="form-check ml-2 mx-auto m-t-10">
              <input
                  id="highBloodPressure"
                  v-model="highBloodPressure"
                  class="form-check-input"
                  disabled
                  type="checkbox"
              />
              <label class="form-check-label" for="highBloodPressure">
                {{ $t("medicalHistory.default.highBloodPressure") }}
              </label>
            </div>
            <div class="form-check ml-2 mx-auto m-t-10">
              <input
                  id="diabetes"
                  v-model="diabetes"
                  class="form-check-input"
                  disabled
                  type="checkbox"
              />
              <label class="form-check-label" for="diabetes">
                {{ $t("medicalHistory.default.diabetes") }}
              </label>
            </div>
            <div class="form-check ml-2 mx-auto m-t-10">
              <input
                  id="medicalHistoryOther"
                  v-model="other"
                  class="form-check-input"
                  disabled
                  type="checkbox"
              />
              <label class="form-check-label" for="medicalHistoryOther">
                {{ $t("medicalHistory.default.medicalHistoryOther") }}
              </label>
            </div>

            <div v-if="other" class="form-check p-l-0 mx-auto">
              <textarea id="otherText" v-model="otherText" class="form-control" disabled rows="2"></textarea>
              <div v-show="errors['otherText']" id="err_other" class="form-error">{{ errors['otherText'] }}</div>
            </div>

          </div>
          <div class="col-2 p-0 medical-history-change">
            <router-link :to="{ name: 'EditMedicalHistory' }" class="required-text">
              {{ $t("account.default.change") }}
            </router-link>
          </div>
        </div>
        
        <div class="account-delete-btn mt-5 text-first">
          <router-link :to="{ name: 'DeleteAccount'}" class="btn btn-dark" role="button">
            {{ $t("account.default.accountDeletion") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Common from '../assets/jsipclient/common'

export default {
  name: "AccountSettings",
  data() {
    return {
      lastName: '',
      firstName: '',
      birthday: '',
      gender: '',
      nationality: "",
      email: '',
      address: '',
      phoneNo: '',
      hasCoronaVaccination: "",
      coronaVaccinationCount: null,
      latestCoronaVaccinationDate: '',
      latestPcrTestResult: "",
      latestPcrTestDate: '',
      anemia: null,
      asthma: null,
      heartDisease: null,
      kidneyDisease: null,
      epilepsy: null,
      highBloodPressure: null,
      diabetes: null,
      other: null,
      otherText: null,
      vaccinationData: {},
      pcrTestData: {},
      errors: []
    }
  },
  computed: {
    ...mapGetters(['config']),
  },
  watch: {
    async $route(to, from) {
      // Called from back or next
      await this.getUser()
    }
  },
  mounted() {
    window.scrollTo({top: 0, behavior: "auto"})
    this.getUser()
  },
  methods: {
    getGender(gender) {
      switch (gender) {
        case 1:
          return this.$t('account.editWithoutPassword.male')
        case 2:
          return this.$t('account.editWithoutPassword.female')
        case 3:
          return this.$t('account.editWithoutPassword.doNotAnswer')
      }
    },
    getHasCoronaVaccination(coronaVaccinationCount) {
      switch(coronaVaccinationCount) {
        case 0:
          return this.$t('account.editWithoutPassword.none')
        case 1:
          return this.$t('account.editWithoutPassword.once')
        case 2:
          return this.$t('account.editWithoutPassword.twice')
        case 3:
          return this.$t('account.editWithoutPassword.thirdTime')
        case 4:
          return this.$t('account.editWithoutPassword.fourthTime')
        case 5:
          return this.$t('account.editWithoutPassword.fifthTime')
        case 6:
          return this.$t('account.editWithoutPassword.sixthTime')
        case 7:
          return this.$t('account.editWithoutPassword.seventhTime')
        case 8:
          return this.$t('account.editWithoutPassword.eightthTime')
        case 9:
          return this.$t('account.editWithoutPassword.ninethTime')
        case 10:
          return this.$t('account.editWithoutPassword.tenthTime')
      }
    },
    getLatestPcrTestResult(getLatestPcrTestResult) {
      switch (getLatestPcrTestResult) {
        case -1:
          return this.$t('account.editWithoutPassword.negative')
        case 0:
          return this.$t('account.editWithoutPassword.notInspected')
        case 1:
          return this.$t('account.editWithoutPassword.positive')
      }
    },
    async getUser() {
      const user_id = this.config.userId
      const token = this.config.token
      try {
        const api = Common.createUserApi(token)
        const jsonObject = await api.usersUserIdGetAsync(user_id)

        this.firstName = jsonObject.firstName
        this.lastName = jsonObject.lastName
        this.birthday = jsonObject.birthday
        this.gender = this.getGender(jsonObject.gender)
        this.nationality = jsonObject.nationalityName
        this.email = jsonObject.email
        this.address = jsonObject.address
        this.phoneNo = jsonObject.phoneNo
        this.vaccinationData = {
          hasCoronaVaccination: jsonObject.hasCoronaVaccination,
          coronaVaccinationCount: jsonObject.coronaVaccinationCount,
          latestCoronaVaccinationDate: jsonObject.latestCoronaVaccinationDate
        }
        this.pcrTestData = {
          latestPcrTestResult: jsonObject.latestPcrTestResult,
          latestPcrTestDate: jsonObject.latestPcrTestDate
        }
        this.coronaVaccinationCount = this.getHasCoronaVaccination(jsonObject.coronaVaccinationCount)
        if(jsonObject.coronaVaccinationCount!=0){
          this.latestCoronaVaccinationDate = jsonObject.latestCoronaVaccinationDate
        }else{
          this.latestCoronaVaccinationDate = null
        }
        this.latestPcrTestResult = this.getLatestPcrTestResult(jsonObject.latestPcrTestResult)
        this.latestPcrTestDate = jsonObject.latestPcrTestDate
        this.anemia = jsonObject.anemia
        this.asthma = jsonObject.asthma
        this.heartDisease = jsonObject.heartDisease
        this.kidneyDisease = jsonObject.kidneyDisease
        this.epilepsy = jsonObject.epilepsy
        this.highBloodPressure = jsonObject.highBloodPressure
        this.diabetes = jsonObject.diabetes
        this.other = jsonObject.other
        this.otherText = jsonObject.otherText
      } catch (error) {
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.account-settings-container {
  padding-left: 0;
  padding-right: 0;
}

.account-delete-btn {
  margin-bottom: 50px;
}

.btn-dark {
  background: #333333;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 16px;
  height: 44px;
  width: 160px;
  line-height: 1.8em;
}

.required-text {
  color: #2663D1;
  margin-top: 2.2rem !important;
  font-weight: bold;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  display: block;
  text-decoration: none;
}

input[type="text"] {
  background-color: #EFF2F7;
  border: 1px solid #EFF2F7;
  height: 48px;
}

select {
  background-color: #EFF2F7;
}

label {
  font-size: 16px;
  color: #333333;
}

.medical-history-change {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 576px) {

  .change{
    padding-top: 24px !important;
  }

  .vaccine-status {
    flex: 0 0 auto;
    width: 33.3%;
  }

  .vaccine-date {
    flex: 0 0 auto;
    width: 50%;
  }

  .pcr-status {
    flex: 0 0 auto;
    width: 33.3%;
  }

  .pcr-date {
    flex: 0 0 auto;
    width: 50%;
  }

  .card {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0;
  }

  .account-delete-btn {
    margin-bottom: 20px;
  }
}

.form-check {
  &-input:disabled, &-input[disabled], &-input:disabled ~ &-label, &-input[disabled] ~ &-label {
    opacity: 1;
  }
}

</style>
